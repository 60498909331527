import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import noop from "lodash/noop"

import { Table, Button, Tag } from "antd";
import { MessageOutlined } from "@ant-design/icons";

import { ContactStatus } from "../../../constant";
import AgeStatusContainer from "../../shared/AgeStatusContainer";

const SPECIAL_COLUMNS = {
  SEGMENTS: {
    title: "Segments",
    dataIndex: "Segments",
    key: "segments",
    width: "25%",
    render: (segments) => <div style={{minWidth:"200px"}}>
      {segments.map(({Title}) => <Tag  key={Title}>{Title}</Tag>)}
    </div>
  },
  STATUS: {
    title: "Status",
    dataIndex: "OptStatus",
    key: "status",
    width: "10%",
    render: (status) => status === ContactStatus.OptInvited ? "Invited" : "Unsubscribed",
  },
  DATE_INVITED: {
    title: "Date Invited",
    dataIndex: "InvitedAt",
    key: "invited_at",
    width: "22%",
    render: (invitedAt) => moment(invitedAt).format('MM/DD/YYYY @h:mma zz')
  },
}

const ContactsTable = ({
  contacts = [],
  status = [],
  selectedContacts=[],
  loading,
  pagination,
  onSelect = noop,
  onContactSelect = noop
}) => {
  const history = useHistory();

  const baseColumns = useMemo(() => [
    {
    width: "10px",
    render: ({Id, OptStatus, PhoneNumber, LeadStatus}) => {
      if([ContactStatus.OptIn, ContactStatus.Closed].includes(OptStatus) && PhoneNumber) {
        return (
          <Button
            style={{
              'display': 'flex',
              'alignItems': 'center',
              'justifyContent': 'center'
            }}
            shape="circle"
            icon={<MessageOutlined style={{verticalAlign: 0}} />}
            onClick={() => {
              if (LeadStatus !== 1) {
                OptStatus === ContactStatus.OptIn
                  ? history.push("/dashboard/inbox/all-messages/", { contactId: Id })
                  : history.push("/dashboard/inbox/opt-out-messages/", { contactId: Id });
              } else {
                history.push("/dashboard/inbox/lead-messages/", { contactId: Id });
              }
            }}
          />
        )
      }
      return null;
    },
  }, {
    title: "First Name",
    dataIndex: "FirstName",
    key: "first_name",
    width: "13%",
  }, {
    title: "Last Name",
    dataIndex: "LastName",
    key: "last_name",
    width: "13%",
  }, {
    title: "Age",
    dataIndex: "AgeGatedStatus",
    key: "age",
    width: "8%",
    render: (age) => age === 1 ? <AgeStatusContainer/> : "",
  }, {
    title: "Phone Number",
    dataIndex: "PhoneNumber",
    key: "phone_number",
    width: "13%",
  }, {
    title: "Email",
    dataIndex: "Email",
    key: "email",
    width:"22%"
  }], [ history ]);

  const baseColumnsWithStatus = useMemo(() => [
    {
    width: "10px",
    render: ({Id, OptStatus, PhoneNumber}) => {
      if([ContactStatus.OptIn, ContactStatus.Closed].includes(OptStatus) && PhoneNumber) {
        return (
          <Button
            shape="circle"
            icon={<MessageOutlined style={{verticalAlign: 0}} />}
            onClick={() => {
              OptStatus === ContactStatus.OptIn
                ? history.push("/dashboard/inbox/all-messages/", { contactId: Id })
                : history.push("/dashboard/inbox/opt-out-messages/", { contactId: Id });
            }}
          />
        )
      }
      return null;
    },
  }, {
    title: "First Name",
    dataIndex: "FirstName",
    key: "first_name",
    width: "20%",
  }, {
    title: "Last Name",
    dataIndex: "LastName",
    key: "last_name",
    width: "20%",
  }, {
    title: "Age",
    dataIndex: "AgeGatedStatus",
    key: "age",
    width: "8%",
    render: (age) => age === 1 ? <AgeStatusContainer/> : "",
  }, {
    title: "Phone Number",
    dataIndex: "PhoneNumber",
    key: "phone_number",
    width: "13%",
  }, {
    title: "Email",
    dataIndex: "Email",
    key: "email",
    width: "30%",
  }], [ history ]);

  const columns = useMemo(() => {
    if(status.includes(ContactStatus.OptInvited)) {
      return [...baseColumns, SPECIAL_COLUMNS.STATUS, SPECIAL_COLUMNS.DATE_INVITED];
    }
    if(status.includes(ContactStatus.OptOut)) {
      return [...baseColumnsWithStatus, SPECIAL_COLUMNS.STATUS];
    }
    return [...baseColumns, SPECIAL_COLUMNS.SEGMENTS];
  }, [baseColumns, status,baseColumnsWithStatus])

  const preventTyping = (e) => {
    // Get the value of the key that was pressed
    const key = e.key;
    // Allow numeric keys, backspace, and arrow keys
    if (key !== 'Backspace' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
      e.preventDefault(); // Prevent the default behavior
    }
  };

  useEffect(() => {
    setTimeout(() =>{
      console.log(document.querySelector('.contacts-table .ant-select-selection-search-input'));
      if(document.querySelector('.contacts-table .ant-select-selection-search-input')) {
        document.querySelector('.contacts-table .ant-select-selection-search-input').style['caret-color'] = 'transparent';
        document.querySelector('.contacts-table .ant-select-selection-search-input').addEventListener('keydown', preventTyping)
      }
    }, 2000)
    return () => {
      if(document.querySelector('.contacts-table .ant-select-selection-search-input')) {
        document.querySelector('.contacts-table .ant-select-selection-search-input').removeEventListener('keydown', preventTyping)
      }
    }
  },[]);
  return (
    <Table
      rowKey="Id"
      className='contacts-table'
      columns={columns}
      dataSource={contacts}
      loading={loading}
      pagination={pagination}
      tableLayout="auto"
      scroll={{ x: '100%', y: 'calc(100vh - 350px)' }}
      rowSelection={{
        onChange: onSelect,
        selectedRowKeys: selectedContacts,
      }}
      onRow={(contact) => ({
        onClick: () => contact.LeadStatus !== 1 ? onContactSelect(contact) : null,
      })}
    />
  )
};

export default ContactsTable;
