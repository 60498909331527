import React, { useCallback, useEffect, useMemo, useState } from "react";

import isEmpty from "lodash/isEmpty";

import { Button, Input, Spin } from "antd";

import ContactSegments, { PRESET_SEGMENTS_KEYS } from "../../components/organisms/ContactSegments";
import { SearchOutlined } from "@ant-design/icons";
import { ContactStatus } from "../../constant";
import { useGetContacts } from "../../hooks/api/contacts";
import ContactsTable from "../../components/organisms/ContactsTable";
import ContactDetails from "../../components/organisms/ContactDetails";
import ContactsActions from "../../components/organisms/ContactsActions";
import { useDispatch, useSelector } from "react-redux";
import Socket from "../../utils/socketUtils";
import UploadList from "../../components/UploadList/UploadList";
import { setUploadList } from "../../redux/actions";
import { useQueryClient } from "@tanstack/react-query";

const Contacts = () => {
    const [contactDetails, setContactDetails] = useState(null);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [deleteSegmentLoading, setDeleteSegmentLoading] = useState(false);
    const [selectedSegments, setSelectedSegments] = useState([PRESET_SEGMENTS_KEYS.ACTIVE]);
    const [showRefreshText, setShowRefreshText] = useState(false);
    const queryClient = useQueryClient();
    const [query, setQuery] = useState("");
    const { user } = useSelector((state) => state.auth);
    const { uploads } = useSelector((state) => state.contacts);
    const dispatch = useDispatch();
    // console.log("uploads in contacts", uploads);
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 15,
        total: 0,
        current: 1
    });

    const contactsParams = useMemo(() => {
        const params = {
            Q: query || undefined,
            OptStatus: [],
            ByPhoneNumber: false,
            Page: pagination.page,
            Limit: pagination.pageSize
        };

        if (selectedSegments.includes(PRESET_SEGMENTS_KEYS.INVITED))
            params.OptStatus.push(ContactStatus.OptInvited);
        if (selectedSegments.includes(PRESET_SEGMENTS_KEYS.UNSUBSCRIBED))
            params.OptStatus.push(ContactStatus.OptOut);

        if (selectedSegments.includes(PRESET_SEGMENTS_KEYS.LEAD)) {
            params.LeadStatus = 1;
        }
        if (selectedSegments.includes(PRESET_SEGMENTS_KEYS.COLD_LEAD)) {
            params.LeadStatus = 1;
            params.ColdStatus = 1;
        }

        if (selectedSegments.includes(PRESET_SEGMENTS_KEYS.ACTIVE)) {
            params.OptStatus.push(ContactStatus.OptIn, ContactStatus.Closed);
        } else {
            const segmentIds = selectedSegments.filter((s) => !isNaN(s));
            if (!isEmpty(segmentIds)) {
                params.SegmentIds = segmentIds;
                params.OptStatus.push(ContactStatus.OptIn, ContactStatus.Closed);
            } else {
                // if (selectedSegments.includes("LEAD-")) {
                const leadSegment = selectedSegments.filter((s) => s.startsWith("LEAD-"));
                if (leadSegment.length > 0) {
                    params.LeadStatus = 1;
                    params.SegmentIds = selectedSegments
                        .filter((s) => s.includes("LEAD-"))
                        .map((o) => parseInt(o.replace("LEAD-", "")));
                } else {
                    const coldLeadSegment = selectedSegments.filter((s) => s.startsWith("COLD-"));
                    if (coldLeadSegment.length > 0) {
                        params.ColdStatus = 1;
                        params.LeadStatus = 1;
                        params.SegmentIds = selectedSegments
                            .filter((s) => s.includes("COLD-"))
                            .map((o) => parseInt(o.replace("COLD-LEAD-", "")));
                    }
                }
                // }
            }
        }

        return params;
    }, [selectedSegments, pagination, query]);

    const [data, { isFetching, refetch }] = useGetContacts(contactsParams, {
        onSuccess({ TotalContacts }) {
            setPagination({ ...pagination, total: TotalContacts });
            clearSelection();
        },
        refetchOnWindowFocus: false
    });
    const reftechHandler = () => {
        refetch();
    };
    const clearSelection = () => setSelectedContacts([]);

    const handleContactUploadEvent = useCallback(
        (data) => {
            // setJoined(true);
            console.log("DAT", data);
            console.log("uploads", uploads);
            // eslint-disable-next-line eqeqeq
            const uploadIndex = uploads.findIndex((upload) => upload.id == data.uploadId);
            if (uploadIndex !== -1) {
                const newUploads = [...uploads];
                newUploads[uploadIndex] = {
                    ...newUploads[uploadIndex],
                    ...data,
                    uploaded: true
                };
                dispatch(setUploadList(newUploads));
            }
            setShowRefreshText(true);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [uploads]
    );

    useEffect(() => {
        console.log("----SUER", user);
        Socket.joinRoom({ room: user.Id });
        Socket.listenContactUpload(user.Id, handleContactUploadEvent);
        return () => {
            Socket.leaveRoom({ room: user.Id });
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleContactUploadEvent]);

    return (
        <div className="flex h-[calc(100vh-80px)]">
            <ContactSegments
                onChange={(segments) => setSelectedSegments(segments) && clearSelection()}
                value={selectedSegments}
            />
            <div className="flex flex-col w-full h-full scroll-styling">
                <div className="flex w-full items-center justify-between px-10 py-4">
                    <div className="flex gap-4">
                        <ContactsActions
                            selectedContacts={selectedContacts
                                .map((id) => data?.Contacts?.find(({ Id }) => Id === id))
                                .filter((c) => c)}
                            selectedSegments={selectedSegments}
                            fetchParams={contactsParams}
                            setSelectedSegments={setSelectedSegments}
                            setDeleteSegmentLoading={setDeleteSegmentLoading}
                            onAction={clearSelection}
                            onSuccess={() => {
                                setPagination({ ...pagination, page: 1, current: 1 });
                                clearSelection();
                                refetch();
                            }}
                            contacts={data?.Contacts}
                        />
                        {!isEmpty(selectedContacts) && (
                            <Button size="large" shape="round" onClick={clearSelection}>
                                Clear
                            </Button>
                        )}
                    </div>
                    <div>
                        {showRefreshText && (
                            <span
                                className="text-lg text-blue-600 font-medium mr-3"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    queryClient.invalidateQueries(["contacts", contactsParams]);
                                    queryClient.invalidateQueries(["contactsTotalCount"]);
                                    setShowRefreshText(false);
                                }}
                            >
                                Refresh
                            </span>
                        )}
                        <span className="text-xl text-secondary font-medium">
                            {isFetching ? <Spin size="small" /> : data?.TotalContacts} Contacts
                        </span>
                    </div>
                </div>
                <div
                    className="bg-gray-100 w-full"
                    style={{
                        paddingRight: "1.2rem",
                        paddingLeft: "0.5rem",
                        paddingBottom: "0.5rem",
                        paddingTop: "0.5rem"
                    }}
                >
                    <Input
                        style={{ borderRadius: "0px" }} // Set the border radius
                        size="large"
                        prefix={<SearchOutlined />}
                        placeholder="Search"
                        value={query}
                        onChange={(ev) => setQuery(ev.target.value)}
                    />
                </div>
                <ContactsTable
                    contacts={data?.Contacts}
                    status={contactsParams.OptStatus}
                    loading={isFetching || deleteSegmentLoading}
                    pagination={{
                        ...pagination,
                        responsive: true,
                        onChange: (page, pageSize) => {
                            setPagination({ ...pagination, page, pageSize, current: page });
                            clearSelection();
                        }
                    }}
                    onSelect={(selection) => setSelectedContacts(selection)}
                    selectedContacts={selectedContacts}
                    onContactSelect={setContactDetails}
                />
            </div>
            <ContactDetails
                reftechHandler={reftechHandler}
                refetch={refetch}
                data={contactDetails}
                onClose={() => setContactDetails(null)}
            />
            <UploadList />
        </div>
    );
};

export default Contacts;

