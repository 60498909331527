import React from "react";
import "../../App.css";
import { connect } from "react-redux";
import { uploadCsvContacts, downloadCsvTemplate, setUploadList } from "../../redux/actions";
import { errorToaster, successToaster } from "../../utils/loggerUtils";
import { WrongCross, CorrectTick } from "../../assets/svg-icon/index";
import { Segmented, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
let FILE_TYPES = ["vnd.ms-excel", "csv", ""]; // TODO: remove empty string
class ImportContactModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSuccessful: false,
            isFailed: false,
            uploadedLength: null,
            successContacts: [],
            failedContacts: [],
            isUploaded: false,
            isLoading: false,
            contactToShow: "All"
        };
        this.dragCounter = 0;
        this.abortController = null;
    }
    handleCsvDownloadClick = (e) => {
        e.preventDefault();
        this.props.downloadCsvTemplate().then((res) => {
            let hiddenElement = document.createElement("a");
            hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(res);
            hiddenElement.target = "_blank";
            hiddenElement.download = `template_${new Date().getTime()}.csv`;
            hiddenElement.click();
        });
    };
    handleFileUpload = (fileObj) => {
        const fileType = fileObj.type.split("/").pop();
        this.abortController = new AbortController();
        // console.log(fileObj, this.props.uploads);
        const uploads = [...this.props.uploads];

        const alreadyUploading = uploads.find((o) => !o.uploaded);
        if (alreadyUploading) {
            errorToaster("Please wait for the current upload to complete.");
            return;
        }
        if (FILE_TYPES.includes(fileType)) {
            this.setState({ isLoading: true });
            const randomId = Date.now();
            this.props
                .uploadCsvContacts(fileObj, this.abortController.signal, randomId)
                .then((res) => {
                    // let { FailedContacts, SuccessContacts, UpdatedContacts } = res;
                    // if (!!SuccessContacts.length) {
                    //     this.setState({
                    //         isSuccessful: true,
                    //         isFailed: false,
                    //         uploadedLength: SuccessContacts.length,
                    //         isLoading: false
                    //     });
                    //     if (this.props.onSuccess) {
                    //         this.props.onSuccess();
                    //     }
                    //     this.props.handleImportContactAlertOpen(SuccessContacts.length);
                    // } else {
                    //     this.setState({
                    //         isFailed: true,
                    //         isSuccessful: false,
                    //         uploadedLength: FailedContacts.length,
                    //         isLoading: false
                    //     });
                    // }
                    if (this.props.onSuccess) {
                        this.props.onSuccess();
                    }
                    this.setState({
                        // successContacts: SuccessContacts,
                        // failedContacts: FailedContacts,
                        // updateContacts: UpdatedContacts,
                        // isUploaded: true,
                        isLoading: false
                    });
                    uploads.push({
                        filename: fileObj.name,
                        uploaded: false,
                        SuccessContacts: [],
                        UpdatedContacts: [],
                        FailedContacts: [],
                        id: randomId
                    });
                    this.props.setUploadList([...uploads]);
                    successToaster("Contacts uploading in progress.");
                })
                .catch((err) => {
                    this.setState({ isLoading: false });
                    console.log(err);
                    errorToaster("Failed to upload contacts. Please try again.");
                });
        } else {
            errorToaster("Invalid File Type");
        }
    };

    render() {
        const {
            isUploaded,
            isLoading,
            contactToShow,
        } = this.state;
        const { handleImportContactModalClose, uploads, currentIndex } = this.props;
        return (
            <div>
                {!isUploaded && currentIndex === undefined ? (
                    <div className="import-contact-modal">
                        <div className="importcontact-modal-content">
                            <div className="importcontact-modal-header">
                                <span
                                    className="close importcontact-closebutton"
                                    onClick={handleImportContactModalClose}
                                >
                                    &times;
                                </span>
                                <h3>Import Contacts</h3>
                            </div>
                            <div className="importcontact-text">
                                <p>Import Contacts into the system using the template provided.</p>

                                <div className="importcontact-btn">
                                    <button
                                        onClick={this.handleCsvDownloadClick}
                                        className="importcontact-modal-btn"
                                    >
                                        Download Template
                                    </button>
                                    <input
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={(e) => {
                                            let event = e;
                                            this.handleFileUpload(event.target.files[0]);
                                            event.target.value = null;
                                        }}
                                        accept=".csv"
                                        ref={(ref) => (this.inputFile = ref)}
                                    />
                                    <button
                                        className="importcontact-modal-btn upload-contact-file-btn"
                                        onClick={() => this.inputFile.click()}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <Spin
                                                style={{ marginRight: 4 }}
                                                size="small"
                                                indicator={<LoadingOutlined spin />}
                                            />
                                        ) : null}{" "}
                                        Upload File
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="import-contact-csv-modal">
                        <div className="importcontactcsv-modal-content">
                            <div className="importcontactcsv-modal-header">
                                <span
                                    onClick={handleImportContactModalClose}
                                    className="close importcontactcsv-closebutton"
                                >
                                    &times;
                                </span>
                                <h3>Import Contact from CSV</h3>
                            </div>
                            <div className="importcontactcsv-text">
                                <p>
                                    <img src={CorrectTick} className="img-fluid" alt="img" />
                                    {uploads[currentIndex]?.SuccessContacts?.length || 0}{" "}
                                    {uploads[currentIndex]?.SuccessContacts?.length <= 1
                                        ? "Contact"
                                        : "Contacts"}{" "}
                                    are newly created
                                </p>
                                <p>
                                    <img src={CorrectTick} className="img-fluid" alt="img" />
                                    {uploads[currentIndex]?.UpdatedContacts?.length || 0}{" "}
                                    {uploads[currentIndex]?.UpdatedContacts?.length <= 1
                                        ? "Contact"
                                        : "Contacts"}{" "}
                                    are updated
                                </p>
                                {/* <p>
                                        <img src={CorrectTick} className="img-fluid" alt="img" />
                                        {updateContacts.length} Contacts are updated
                                </p> */}
                                <p>
                                    <img src={WrongCross} className="img-fluid" alt="img" />
                                    {uploads[currentIndex]?.FailedContacts?.length}{" "}
                                    {uploads[currentIndex]?.FailedContacts?.length <= 1
                                        ? "Contact"
                                        : "Contacts"}{" "}
                                    have failed
                                </p>
                                <Segmented
                                    options={["All", "New", "Updated", "Failed"]}
                                    onChange={(value) => {
                                        this.setState({ contactToShow: value });
                                    }}
                                    className="importContact-segmented"
                                />
                                <label style={{ marginTop: 8 }}>
                                    List of {contactToShow} contacts
                                </label>

                                <div className="tabledata-listing">
                                    <table>
                                        <tbody>
                                            {contactToShow !== "Updated" &&
                                            contactToShow !== "Failed"
                                                ? uploads[currentIndex]?.SuccessContacts?.map(
                                                      (each) => {
                                                          return (
                                                              <tr key={each.PhoneNumber}>
                                                                  <td>{each.FirstName} </td>
                                                                  <td>{each.LastName}</td>
                                                                  <td>{each.PhoneNumber}</td>
                                                                  <td>New</td>
                                                              </tr>
                                                          );
                                                      }
                                                  )
                                                : null}
                                            {contactToShow !== "New" && contactToShow !== "Failed"
                                                ? uploads[currentIndex]?.UpdatedContacts?.map(
                                                      (each) => {
                                                          return (
                                                              <tr key={each.PhoneNumber}>
                                                                  <td>{each.FirstName} </td>
                                                                  <td>{each.LastName}</td>
                                                                  <td>{each.PhoneNumber}</td>
                                                                  <td>Updated</td>
                                                              </tr>
                                                          );
                                                      }
                                                  )
                                                : null}
                                            {contactToShow !== "New" && contactToShow !== "Updated"
                                                ? uploads[currentIndex]?.FailedContacts?.map(
                                                      (each) => {
                                                          return (
                                                              <tr key={each.PhoneNumber}>
                                                                  <td>{each.FirstName} </td>
                                                                  <td>{each.LastName}</td>
                                                                  <td>{each.PhoneNumber}</td>
                                                                  <td>Failed</td>
                                                              </tr>
                                                          );
                                                      }
                                                  )
                                                : null}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="importcontactcsv-btn">
                                    <button
                                        onClick={handleImportContactModalClose}
                                        className="importcontactcsv-modal-btn"
                                    >
                                        {currentIndex === undefined ? "Cancel" : "Close"}
                                    </button>
                                    {currentIndex === undefined && (
                                        <button
                                            onClick={() => {
                                                this.setState({ isUploaded: false });
                                            }}
                                            className="importcontactcsv-modal-btn"
                                        >
                                            Import Contacts
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        uploads: state.contacts.uploads
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        uploadCsvContacts: (data, signal, randomId) =>
            dispatch(uploadCsvContacts(data, signal, randomId)),
        downloadCsvTemplate: () => dispatch(downloadCsvTemplate()),
        setUploadList: (data) => dispatch(setUploadList(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportContactModal);

