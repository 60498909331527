import React from "react";
import { withRouter } from "react-router-dom";
import "../../../App.css";
import {
    OnboardIcon,
    CloseConversationIcon,
    IconArrowDownGray,
    SendContactCardIcon
} from "../../../assets/svg-icon";
import withUser from "../../../hoc/withUser";
import { connect } from "react-redux";
import { Dropdown } from "../../Dropdown";
import { CustomModal } from "../../modal";
import ConfirmationModal from "../../../views/AllContacts/ConfirmationModal";
import ContactAlertModal from "../../Contact/ContactAlertModal";
import { SuccessIcon } from "../../../assets/svg-icon/index";
import { ContactStatus } from "../../../constant";
import uuidv4 from "uuid/v4";
import {
    changeContactStatus,
    updateChatListOnStatusChange,
    sendOnBoardForm,
    sendContactCard,
    updateChatAfterAddingMessage,
    getChatMessageList,
    getChatNotificationStatus
} from "../../../redux/actions";
import { errorToaster } from "../../../utils/loggerUtils";
import { ChatMessageTypes } from "../../../config/constants";
import { convertDateTime } from "../../../utils/dateTimeUtils";
class InboxChatboxHeader extends React.Component {
    constructor() {
        super();
        this.threeDottedDropDown = React.createRef();
        this.innerReference = React.createRef();
        this.state = {
            showContactCard: false,
            showContactCardAlert: false,
            showOnBoardForm: false,
            showOnBoardFormAlert: false,
            sendOnBoardFormLoading: false,
            sendContactCardLoading: false,
            checkboxStatus: false
        };
    }
    handleConversationStatus = (id, isChatOptedIn) => {
        let OptStatus = isChatOptedIn ? ContactStatus.OptIn : ContactStatus.Closed;
        const body = {
            Ids: [id],
            OptStatus: OptStatus
        };
        this.props
            .changeContactStatus(body)
            .then((res) => {
                this.props.updateChatListOnStatusChange(id);
            })
            .catch((err) => {
                errorToaster(err.Message);
            });
    };
    handleContactCardStatus = () => {
        this.setState({ ...this.state, showContactCard: !this.state.showContactCard });
    };
    handleshowOnBoardFormStatus = () => {
        this.setState({ ...this.state, showOnBoardForm: !this.state.showOnBoardForm });
    };
    handleSendContactCard = () => {
        this.setState({ ...this.state, sendContactCardLoading: true });
        this.props
            .sendContactCard({ Ids: [this.props.currentContactId] })
            .then((res) => {
                const uniqueId = uuidv4();
                this.setState({
                    ...this.state,
                    showContactCard: !this.state.showContactCard,
                    showContactCardAlert: !this.state.showContactCardAlert,
                    sendContactCardLoading: false
                });
                let time = convertDateTime(Date.now())
                    .getTimeZoneMoment()
                    .format("MM/DD/YYYY, hh:mm A")
                    .replace(", ", " at ");
                this.props.getChatMessageList(
                    this.props.currentChat &&
                        this.props.currentChat.data &&
                        this.props.currentChat.data.LastChatEvent &&
                        this.props.currentChat.data.LastChatEvent.ChatId
                );
                this.props.getChatNotificationStatus(this.props.currentContactId);
                this.props.updateChatAfterAddingMessage({
                    ChatId:
                        this.props.currentChat &&
                        this.props.currentChat.data &&
                        this.props.currentChat.data.LastChatEvent &&
                        this.props.currentChat.data.LastChatEvent.ChatId,
                    Content: `Contact card form sent on ${time}`,
                    SenderId: this.props.user.Id,
                    SenderType: ChatMessageTypes.CONTACTCARDNOTIFICATION,
                    Id: uniqueId,
                    CreatedAt: Date.now(),
                    isAcknowledged: false,
                    Error: false,
                    SenderInfo: JSON.stringify({
                        IsCompany: false,
                        SenderName: this.props.user.Name,
                        SenderId: this.props.user.Id,
                        AutoResponse: false
                    })
                });
            })
            .catch((e) => {
                errorToaster(e.Message);
                this.setState({
                    ...this.state,
                    showContactCard: !this.state.showContactCard,
                    sendContactCardLoading: false
                });
            });
    };
    handleCheckboxStatus = (e) => {
        this.setState({ ...this.state, checkboxStatus: !this.state.checkboxStatus });
    };
    handleshowOnBoardCard = () => {
        this.setState({ ...this.state, sendOnBoardFormLoading: true });
        // console.log(this.state.checkboxStatus, " checkboxStatus ");

        this.props
            .sendOnBoardForm({
                Ids: [this.props.currentContactId],
                SentOnboardForm: this.state.checkboxStatus ? 0 : 1
            })
            .then((res) => {
                const uniqueId = uuidv4();
                this.setState({
                    ...this.state,
                    showOnBoardForm: !this.state.showOnBoardForm,
                    showOnBoardFormAlert: !this.showOnBoardFormAlert,
                    sendOnBoardFormLoading: false
                });
                let time = convertDateTime(Date.now())
                    .getTimeZoneMoment()
                    .format("MM/DD/YYYY, hh:mm A")
                    .replace(", ", " at ");
                this.props.getChatNotificationStatus(this.props.currentContactId);
                this.props.getChatMessageList(
                    this.props.currentChat &&
                        this.props.currentChat.data &&
                        this.props.currentChat.data.LastChatEvent &&
                        this.props.currentChat.data.LastChatEvent.ChatId
                );
                this.props.updateChatAfterAddingMessage({
                    ChatId:
                        this.props.currentChat &&
                        this.props.currentChat.data &&
                        this.props.currentChat.data.LastChatEvent &&
                        this.props.currentChat.data.LastChatEvent.ChatId,
                    Content: `Onboard form sent on ${time}`,
                    SenderId: this.props.user.Id,
                    SenderType: ChatMessageTypes.ONBOARDFORMNOTIFICATION,
                    Id: uniqueId,
                    CreatedAt: Date.now(),
                    isAcknowledged: false,
                    Error: false,
                    SenderInfo: JSON.stringify({
                        IsCompany: false,
                        SenderName: this.props.user.Name,
                        SenderId: this.props.user.Id,
                        AutoResponse: false
                    })
                });
            })
            .catch((e) => {
                console.log(e);
                errorToaster(e.Message);
                this.setState({
                    ...this.state,
                    showOnBoardFormAlert: !this.showOnBoardFormAlert,
                    sendOnBoardFormLoading: false
                });
            });
    };
    handleContactCardAlertStatus = () => {
        this.setState({ ...this.state, showContactCardAlert: !this.state.showContactCardAlert });
    };
    handleshowOnBoardFormAlertStatus = () => {
        this.setState({ ...this.state, showOnBoardFormAlert: !this.state.showOnBoardFormAlert });
    };
    changeNewButtonDropDownStatus = () => {
        if (
            this.threeDottedDropDown &&
            this.threeDottedDropDown.current &&
            this.threeDottedDropDown.current.isDropDownInstance()
        ) {
            this.threeDottedDropDown.current.openDropDown();
        }
    };
    render() {
        const { currentContactId, isChatOptedIn, name, isEditing, contactData } = this.props;
        const {
            showContactCard,
            showContactCardAlert,
            showOnBoardFormAlert,
            showOnBoardForm
        } = this.state;
        return (
            <div className="middle-chat-header">
                <div className="inbox-chatbox-chat-num">
                    {contactData.LeadStatus === 0 ? (
                        <div
                            className="circleLeftArrow"
                            onClick={() => this.props.changeEditingState()}
                        >
                            <img
                                src={IconArrowDownGray}
                                className={!isEditing ? `img-fluid left-arrow` : `img-fluid `}
                                alt="img"
                            />
                        </div>
                    ) : null}
                    <h3
                        onClick={() =>
                            contactData.LeadStatus === 0 && this.props.changeEditingState()
                        }
                    >
                        {name}
                    </h3>
                </div>
                {contactData.LeadStatus === 0 ? (
                    <div className="middle-chat-header-threedots">
                        <span
                            onClick={this.changeNewButtonDropDownStatus}
                            ref={this.innerReference}
                        >
                            ...
                        </span>
                        <Dropdown
                            ref={this.threeDottedDropDown}
                            innerReference={this.innerReference}
                        >
                            <div className="middle-chat-header-threedots-dropdown">
                                <ul>
                                    <li
                                        className="cursor-pointer"
                                        onClick={() => this.handleshowOnBoardFormStatus()}
                                    >
                                        <label className="cursor-pointer">
                                            <img
                                                src={OnboardIcon}
                                                className="img-fluid"
                                                alt="img"
                                            />
                                            Send Onboard Form
                                        </label>
                                    </li>
                                    <li
                                        className="cursor-pointer"
                                        onClick={() => this.handleContactCardStatus()}
                                    >
                                        <label className="cursor-pointer">
                                            {" "}
                                            <img
                                                src={SendContactCardIcon}
                                                className="img-fluid"
                                                alt="img"
                                            />
                                            Send Contact Card
                                        </label>
                                    </li>
                                    <li
                                        className="cursor-pointer"
                                        onClick={() =>
                                            this.handleConversationStatus(
                                                currentContactId,
                                                isChatOptedIn
                                            )
                                        }
                                    >
                                        <label className="cursor-pointer">
                                            <img
                                                src={CloseConversationIcon}
                                                className="img-fluid"
                                                alt="img"
                                            />
                                            {isChatOptedIn ? (
                                                <label className="cursor-pointer">
                                                    Open Conversation
                                                </label>
                                            ) : (
                                                <label className="cursor-pointer">
                                                    Close Conversation
                                                </label>
                                            )}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </Dropdown>
                    </div>
                ) : (
                    <div className="middle-chat-header-threedots">
                        <span></span>
                    </div>
                )}
                {showOnBoardForm ? (
                    <CustomModal
                        isOpen={showOnBoardForm}
                        handleModalState={() => this.handleshowOnBoardFormStatus()}
                    >
                        <ConfirmationModal
                            handleCheckboxStatus={this.handleCheckboxStatus}
                            checkboxStatus={this.state.checkboxStatus}
                            checkBoxMessage={"Send onboard form only with no onboard message"}
                            checkBox={true}
                            color={"green"}
                            handleModalClose={() => this.handleshowOnBoardFormStatus()}
                            handleModalClick={this.handleshowOnBoardCard}
                            title={"Send Onboard Form"}
                            content={`User(s) will recieve a message with the Onboardform. Responses will be reflected in their contact card`}
                            buttonContent={"Send Now"}
                            buttonDisabled={this.state.sendOnBoardFormLoading}
                        />
                    </CustomModal>
                ) : null}
                {showOnBoardFormAlert ? (
                    <CustomModal
                        isOpen={showOnBoardFormAlert}
                        handleModalState={() => this.handleshowOnBoardFormAlertStatus()}
                    >
                        <ContactAlertModal
                            color={"green"} // todo
                            handleClose={() => this.handleshowOnBoardFormAlertStatus()}
                            icon={SuccessIcon}
                            content={`Onboard form sent`}
                        />
                    </CustomModal>
                ) : null}
                {showContactCard ? (
                    <CustomModal
                        isOpen={showContactCard}
                        handleModalState={() => this.handleContactCardStatus()}
                    >
                        <ConfirmationModal
                            color={"green"}
                            handleModalClose={() => this.handleContactCardStatus()}
                            handleModalClick={this.handleSendContactCard}
                            title={"Resend Contact Card"}
                            content={`The subscriber will receive a message with your contact card. Are you sure you want to continue?`}
                            buttonContent={"Resend Now"}
                            buttonDisabled={this.state.sendContactCardLoading}
                        />
                    </CustomModal>
                ) : null}
                {showContactCardAlert ? (
                    <CustomModal
                        isOpen={showContactCardAlert}
                        handleModalState={() => this.handleContactCardAlertStatus()}
                    >
                        <ContactAlertModal
                            color={"green"} // todo
                            handleClose={() => this.handleContactCardAlertStatus()}
                            icon={SuccessIcon}
                            content={`Contact Card was sent`}
                        />
                    </CustomModal>
                ) : null}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        currentChat: state.chat.currentChat,
        user: state.auth.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateChatListOnStatusChange: (chatId) => dispatch(updateChatListOnStatusChange(chatId)),
        changeContactStatus: (data) => dispatch(changeContactStatus(data)),
        sendOnBoardForm: (data) => dispatch(sendOnBoardForm(data)),
        sendContactCard: (data) => dispatch(sendContactCard(data)),
        updateChatAfterAddingMessage: (data) => dispatch(updateChatAfterAddingMessage(data)),
        getChatMessageList: (id) => dispatch(getChatMessageList(id)),
        getChatNotificationStatus: (id) => dispatch(getChatNotificationStatus(id))
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withUser(InboxChatboxHeader)));

