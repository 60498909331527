import React from "react";
import "../../App.css";
import { connect } from "react-redux";
import {
    uploadCsvContacts,
    downloadCsvTemplate,
    uploadSegmentCsvContacts
} from "../../redux/actions";
import { errorToaster } from "../../utils/loggerUtils";
import { WrongCross, CorrectTick } from "../../assets/svg-icon/index";
import { CloudUpload } from "../../assets/png-icon/index";
import { Checkbox } from "pretty-checkbox-react";
let FILE_TYPES = ["vnd.ms-excel", "csv", ""]; // TODO: remove empty string
class NewSegmentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSuccessful: false,
            isFailed: false,
            uploadedLength: null,
            successContacts: [],
            failedContacts: [],
            isUploaded: false,
            isLoading: false,
            name: "",
            nameError: false,
            isFocused: "",
            includeSegment: false
        };
        this.dragCounter = 0;
        this.abortController = null;
    }
    handleCsvDownloadClick = (e) => {
        e.preventDefault();
        this.props.downloadCsvTemplate().then((res) => {
            let hiddenElement = document.createElement("a");
            hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(res);
            hiddenElement.target = "_blank";
            hiddenElement.download = `template_${new Date().getTime()}.csv`;
            hiddenElement.click();
        });
    };
    handleFileUpload = (fileObj) => {
        const fileType = fileObj.type.split("/").pop();
        this.abortController = new AbortController();

        if (FILE_TYPES.includes(fileType)) {
            this.setState({ isLoading: true });
            this.props
                .uploadSegmentCsvContacts(
                    fileObj,
                    { Title: this.state.name, FormVisibility: this.state.includeSegment },
                    this.abortController.signal
                )
                // .uploadCsvContacts(fileObj, this.abortController.signal)
                .then((res) => {
                    let { FailedContacts, SuccessContacts, UpdatedContacts } = res;
                    if (!!SuccessContacts.length) {
                        this.setState({
                            isSuccessful: true,
                            isFailed: false,
                            uploadedLength: SuccessContacts.length,
                            isLoading: false
                        });
                        // if (this.props.onSuccess) {
                        //     this.props.onSuccess();
                        // }
                        // this.props.handleImportContactAlertOpen(SuccessContacts.length);
                    } else if (FailedContacts.length) {
                        this.setState({
                            isFailed: true,
                            isSuccessful: false,
                            uploadedLength: FailedContacts.length,
                            isLoading: false
                        });
                    }
                    this.setState({
                        successContacts: SuccessContacts,
                        failedContacts: FailedContacts,
                        updateContacts: UpdatedContacts,
                        isUploaded: true
                    });
                    if (this.props.onSuccess) {
                        this.props.onSuccess();
                    }
                    this.props.handleImportContactAlertOpen(SuccessContacts.length);
                })
                .catch((err) => {
                    this.setState({ isLoading: false });
                });
        } else {
            errorToaster("Invalid File Type");
        }
    };

    AddOrRemoveFocus = (data) => {
        this.setState({
            isFocused: data
        });
    };

    handleCheckboxStatus = (e) => {
        this.setState({
            includeSegment: !this.state.includeSegment
        });
    };

    onInputChange = (e) => {
        let isErrorFieldInState = false;
        if (`${e.target.name}Error` in this.state) {
            isErrorFieldInState = true;
        }
        this.setState({
            [e.target.name]: e.target.value,
            ...(isErrorFieldInState && { [`${e.target.name}Error`]: false }),
            isFieldUpdated: true
        });
    };

    render() {
        const {
            isUploaded,
            successContacts,
            failedContacts,
            updateContacts,
            isLoading,
            nameError,
            name,
            isFocused,
            includeSegment
        } = this.state;
        const { handleImportContactModalClose } = this.props;
        return (
            <div>
                {!isUploaded ? (
                    isLoading ? (
                        <>
                            <div className="import-contact-modal">
                                <div className="importcontact-modal-content">
                                    <div className="importcontact-modal-header">
                                        <div className="fileuploadpercentage">
                                            <img src={CloudUpload} alt="img" />
                                            <h1 className="import-contacts-upload-heading">
                                                <span>30%</span> Completed
                                            </h1>
                                            <div className="importcontactcsv-btn">
                                                <button
                                                    onClick={() => this.abortController.abort()}
                                                    className="importcontactcsv-modal-btn"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="import-contact-modal">
                            <div className="importcontact-modal-content">
                                <div className="importcontact-modal-header">
                                    <span
                                        className="close importcontact-closebutton"
                                        onClick={handleImportContactModalClose}
                                    >
                                        &times;
                                    </span>
                                    <h3>New Segment</h3>
                                </div>
                                <div className="importcontact-text">
                                    <div className="contacts-newcontact-form">
                                        <div className="form-group">
                                            <label
                                                className={
                                                    isFocused === "fname" ? `contacts-label` : ``
                                                }
                                            >
                                                Name*
                                            </label>
                                            {nameError ? (
                                                <div className="error-strip">Name is required</div>
                                            ) : null}
                                            <input
                                                onFocus={() => this.AddOrRemoveFocus("fname")}
                                                onBlur={() => this.AddOrRemoveFocus("")}
                                                placeholder="Name"
                                                name="name"
                                                onChange={this.onInputChange}
                                                value={name}
                                            />
                                        </div>
                                        <div
                                            className="form-group"
                                            style={{
                                                display: "flex"
                                                // alignItems: "center"
                                            }}
                                        >
                                            <Checkbox
                                                id="include-segment-checkbox"
                                                checked={includeSegment}
                                                onChange={(e) => this.handleCheckboxStatus(e)}
                                            />
                                            <p>Include the segment in the Onboard Form</p>
                                        </div>
                                        <p>
                                            Import Contacts into the segment using the template
                                            provided.
                                        </p>

                                        <div className="importcontact-btn">
                                            <button
                                                onClick={this.handleCsvDownloadClick}
                                                className="importcontact-modal-btn"
                                            >
                                                Download Template
                                            </button>
                                            <input
                                                style={{ display: "none" }}
                                                type="file"
                                                onChange={(e) => {
                                                    let event = e;
                                                    this.handleFileUpload(event.target.files[0]);
                                                    event.target.value = null;
                                                }}
                                                accept=".csv"
                                                ref={(ref) => (this.inputFile = ref)}
                                            />
                                            <button
                                                className="importcontact-modal-btn upload-contact-file-btn"
                                                onClick={() => this.inputFile.click()}
                                            >
                                                Upload File
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <div className="import-contact-csv-modal">
                        <div className="importcontactcsv-modal-content">
                            <div className="importcontactcsv-modal-header">
                                <span
                                    onClick={handleImportContactModalClose}
                                    className="close importcontactcsv-closebutton"
                                >
                                    &times;
                                </span>
                                <h3>Import Contact from CSV</h3>
                            </div>
                            <div className="importcontactcsv-text">
                                <p>
                                    <img src={CorrectTick} className="img-fluid" alt="img" />
                                    {successContacts.length} Contacts are ready to be imported
                                </p>
                                <p>
                                    <img src={CorrectTick} className="img-fluid" alt="img" />
                                    {updateContacts.length} Contacts are updated
                                </p>
                                <p>
                                    <img src={CorrectTick} className="img-fluid" alt="img" />
                                    {updateContacts.length} Contacts are updated
                                </p>
                                <p>
                                    <img src={WrongCross} className="img-fluid" alt="img" />
                                    {failedContacts.length} Contacts have failed
                                </p>
                                <label>List of failed contacts</label>

                                <div className="tabledata-listing">
                                    <table>
                                        <tbody>
                                            {failedContacts.map((each) => {
                                                return (
                                                    <tr key={each.PhoneNumber}>
                                                        <td>{each.FirstName} </td>
                                                        <td>{each.LastName}</td>
                                                        <td>{each.PhoneNumber}</td>
                                                        <td>Failed</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="importcontactcsv-btn">
                                    <button
                                        onClick={handleImportContactModalClose}
                                        className="importcontactcsv-modal-btn"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={() => {
                                            this.setState({ isUploaded: false });
                                        }}
                                        className="importcontactcsv-modal-btn"
                                    >
                                        Import Contacts
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        uploadCsvContacts: (data, signal) => dispatch(uploadCsvContacts(data, signal)),
        uploadSegmentCsvContacts: (fileData, data, signal) =>
            dispatch(uploadSegmentCsvContacts(fileData, data, signal)),
        downloadCsvTemplate: () => dispatch(downloadCsvTemplate())
    };
};

export default connect(null, mapDispatchToProps)(NewSegmentModal);
