import React from "react";
import { connect } from "react-redux";
import { DebounceInput } from "react-debounce-input";
import "../../App.css";
import { getContactsList } from "../../redux/actions";
import { Spinner } from "../../components/Loader";
import { SearchInput } from "../../components/Input";
import { ContactStatus } from "../../config/constants";
import { getFormattedNumberNoDash } from "../../utils/commonUtils";
import { SELECT_ALL_CONTACTS_TYPES } from "../../constant";

let searching = false;
class TextSmsCampaignContactSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSuggestionsDropdown: false,
            page: 1,
            searchText: "",
            contactsList: [],
            segmentsList: [],
            clearText: false,
            fetchRequestStatus: false
        };
        this.clearSearchText = this.clearSearchText.bind(this);
        this.scrollDiv = React.createRef();
        this.abortController = new AbortController();
    }
    clearTextValue = (flag) => {
        this.setState({ clearText: flag });
    };
    clearSearchText = () => {
        this.setState({ searchText: "" });
        this.handleSearchChange("");
        this.clearTextValue(true);
    };
    componentWillUnmount() {
        this.abortController.abort();
    }
    fileterContactsList = (contacts, segment = null) => {
        const { selectedContacts, selectedSegments } = this.props;
        let updSelectedSegements = selectedSegments;

        if (segment) {
            updSelectedSegements[segment.Id] = segment;
        }

        let filteredContacts = contacts.filter((contact) => {
            let isContactInSegment = false;
            const segments = contact.Segments;

            for (let segment of segments) {
                if (!!updSelectedSegements[segment.Id]) {
                    isContactInSegment = true;
                    break;
                }
            }

            return !isContactInSegment && !selectedContacts[contact.Id];
        });
        return filteredContacts;
    };

    bindDocumentListener = () => {
        if (!document.getElementsByClassName("ReactModalPortal")[0]) {
            document.getElementById("root").addEventListener("click", this.documentClick, false);
            return;
        }
        document
            .getElementsByClassName("ReactModalPortal")[0]
            .addEventListener("click", this.documentClick, false);
    };

    unbindDocumentListener = () => {
        if (!document.getElementsByClassName("ReactModalPortal")[0]) {
            document.getElementById("root").addEventListener("click", this.documentClick, false);
            return;
        }
        document
            .getElementsByClassName("ReactModalPortal")[0]
            .removeEventListener("click", this.documentClick, false);
    };

    documentClick = (e) => {
        const shouldClose = !e.target.classList.contains("prevent-close");
        if (shouldClose) {
            this.setState({ showSuggestionsDropdown: false });
            this.unbindDocumentListener();
        }
    };

    handleInputCLick = () => {
        const { searchText, showSuggestionsDropdown } = this.state;
        if (!showSuggestionsDropdown) {
            this.setState({ contactsList: [] });
            let page = 1;
            this.props
                .getContactsList(
                    {
                        Page: page,
                        OptStatus: ContactStatus.OPT_IN,
                        ...(searchText && { Q: searchText })
                    },
                    this.abortController.signal
                )
                .then((res) => {
                    let list = this.fileterContactsList(res.Contacts);
                    let segments = this.getFilteredSegements(searchText);
                    this.setState({ contactsList: list });
                    this.setState({ segmentsList: segments });
                })
                .catch((err) => {});
            this.setState({
                showSuggestionsDropdown: true,
                page
            });
            this.bindDocumentListener();
        }
    };

    getFilteredSegements = (searchText) => {
        const { segments } = this.props;
        if (!searchText) {
            return segments;
        }
        const updatedSegments = segments.filter(
            (segment) => segment.Title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        );
        return updatedSegments;
    };

    handleSearchChange = (e) => {
        let searchText = e.target ? e.target.value : e;
        let page = 1;
        if (this.state.fetchRequestStatus) {
            this.abortController.abort();
            this.abortController = new AbortController();
        }
        this.setState({
            fetchRequestStatus: true
        });
        this.props
            .getContactsList(
                {
                    Page: page,
                    OptStatus: [ContactStatus.OPT_IN, ContactStatus.OPT_CLOSED],
                    ...(searchText && { Q: searchText })
                },
                this.abortController.signal
            )
            .then((res) => {
                this.setState({
                    fetchRequestStatus: false
                });
                let list = this.fileterContactsList(res.Contacts);
                let segments = this.getFilteredSegements(searchText);
                this.setState({ contactsList: list });
                this.setState({ segmentsList: segments });
            })
            .catch((err) => {});

        this.setState({ searchText, page, contactsList: [] });
        searching = !!searchText;
    };

    handleScroll = (e, noScroll = false) => {
        const { dataAvailable, fetching } = this.props;
        const { searchText } = this.state;
        const bottom =
            noScroll || e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 20;
        if (bottom && dataAvailable && !fetching) {
            let page = this.state.page + 1;
            this.props
                .getContactsList(
                    {
                        Page: page,
                        OptStatus: ContactStatus.OPT_IN,
                        ...(searchText && { Q: searchText })
                    },
                    this.abortController.signal
                )
                .then((res) => {
                    let list = this.fileterContactsList(res.Contacts);
                    this.setState((state) => ({ contactsList: [...state.contactsList, ...list] }));
                })
                .catch((err) => {});
            this.setState({ page });
        }
    };

    onContactClick = (e, contact) => {
        const { contactsList } = this.state;
        let updatedContactList = contactsList.filter((data) => data.Id !== contact.Id);
        this.setState({ contactsList: updatedContactList });
        this.props.onContactClick(contact);
        let container = this.scrollDiv.current;
        if (container) {
            const isScrolable = container.scrollHeight > container.clientHeight;
            if (!isScrolable) {
                this.handleScroll(null, true);
            }
        }
        this.clearSearchText();
    };

    onSagmentClick = (e, segment) => {
        const { contactsList, segmentsList } = this.state;
        let updatedSegmentList = segmentsList.filter((data) => data.Id !== segment.Id);
        this.setState({ segmentsList: updatedSegmentList });
        this.props.onSegmentClick(segment);
        const list = this.fileterContactsList(contactsList, segment);
        this.setState({ contactsList: list });
        this.clearSearchText();
    };

    renderDropdown = () => {
        const { fetching } = this.props;
        const { contactsList, segmentsList } = this.state;
        return (
            <div
                className="contact-search-dropdown fancy-scroll"
                onScroll={this.handleScroll}
                ref={this.scrollDiv}
            >
                {this.props.selectionType !== SELECT_ALL_CONTACTS_TYPES.COMPANY &&
                    !this.props.hideAllContacts && (
                        <ul className="contact-suggestions-list">
                            <li
                                onClick={(e) => this.props.updateAllContactSelection()}
                                className="contact-suggestions-list-item prevent-close text-truncate"
                            >
                                {`Select All Active Contacts`}
                            </li>
                        </ul>
                    )}
                {this.props.selectionType === SELECT_ALL_CONTACTS_TYPES.TEST_CAMPAIGN &&
                    !contactsList.length &&
                    !fetching &&
                    !searching && (
                        <div className="search-dd-title-container">
                            <p>Search Contacts</p>
                        </div>
                    )}
                {segmentsList.length &&
                this.props.selectionType !== SELECT_ALL_CONTACTS_TYPES.COMPANY &&
                this.props.selectionType !== SELECT_ALL_CONTACTS_TYPES.TEST_CAMPAIGN ? (
                    <div>
                        <div className="search-dd-title-container">
                            <p>Segments</p>
                        </div>

                        <ul className="contact-suggestions-list">
                            {segmentsList.map((segment, index) => (
                                <li
                                    onClick={(e) => this.onSagmentClick(e, segment)}
                                    className="contact-suggestions-list-item prevent-close text-truncate"
                                    key={index}
                                >
                                    <span className="tag-inner-container prevent-close">{`${segment.Title}`}</span>
                                    <div></div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}

                <div>
                    {contactsList.length &&
                    (this.props.selectionType !== SELECT_ALL_CONTACTS_TYPES.COMPANY ||
                        this.props.selectionType === SELECT_ALL_CONTACTS_TYPES.TEST_CAMPAIGN) ? (
                        <div className="search-dd-title-container">
                            <p>Contacts</p>
                        </div>
                    ) : null}
                    <ul className="contact-suggestions-list">
                        {contactsList.length &&
                        (this.props.selectionType !== SELECT_ALL_CONTACTS_TYPES.COMPANY ||
                            this.props.selectionType === SELECT_ALL_CONTACTS_TYPES.TEST_CAMPAIGN)
                            ? contactsList.map((contact, index) => {
                                  return (
                                      <li
                                          onClick={(e) => this.onContactClick(e, contact)}
                                          className="contact-suggestions-list-item prevent-close text-truncate"
                                          key={index}
                                      >
                                          {`${contact.FirstName} ${
                                              contact.LastName
                                          }  -  ${getFormattedNumberNoDash(contact.PhoneNumber)}`}
                                      </li>
                                  );
                              })
                            : null}
                        <li className="prevent-close flex-center">
                            {!contactsList.length > 0 &&
                                !segmentsList.length > 0 &&
                                (fetching ? (
                                    <div className="flex-center prevent-close">
                                        <Spinner size="lg" color="gray" />
                                    </div>
                                ) : (
                                    <p className="flex-center prevent-close">
                                        {searching ? "Sorry, No search results found" : ""}
                                    </p>
                                ))}

                            {contactsList.length > 0 && fetching && (
                                <div className="flex-center prevent-close">
                                    <Spinner size="md" color="gray" />
                                </div>
                            )}
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    render() {
        let { showSuggestionsDropdown, searchText, clearText } = this.state;
        return (
            <>
                <div className="sms-campaign-contact-search-wrapper">
                    <DebounceInput
                        element={SearchInput}
                        debounceTimeout={500}
                        onClick={this.handleInputCLick}
                        onChange={this.handleSearchChange}
                        value={searchText}
                        classes="prevent-close"
                        placeholder={this.props.placeholder || "Search"}
                        clearText={clearText}
                        clearSearchText={this.clearTextValue}
                        onFocus={this.props.onFocus}
                        onBlur={this.props.onBlur}
                        disabled={this.props.editCampaign}
                    />
                    {showSuggestionsDropdown ? this.renderDropdown() : null}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        fetching: state.contacts.allContacts.fetching,
        dataAvailable: state.contacts.allContacts.dataAvailable
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getContactsList: (paramsObj) => dispatch(getContactsList(paramsObj))
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    TextSmsCampaignContactSearch
);
