import React from "react";
import { connect } from "react-redux";
import "../../App.css";
import InputMask from "react-input-mask";
import { getFormattedNumber } from "../../utils/commonUtils";
import {
    PhoneIcon,
    EmailIcon,
    SegmentListCross,
    SegmentPlusIcon,
    CloseSideBarIcon,
    AddSegmentCloseIcon,
    SuccessIcon,
    SendContactCardIcon,
    OnBoardIcon,
    contactCardIconGreen,
    onBoardIconGreen
} from "../../assets/svg-icon/index";
import { ChatMessageTypes, ContactStatus, GenderTypes } from "../../config/constants";
import Avatar from "../../components/Avatar/Avatar";
import { Is_Of_Age_Date, ShortDate } from "../../utils/validateHelperUtils";
import {
    getRegisterFormFields,
    getChatNotificationStatus,
    changeContactStatus,
    updateChatListOnStatusChange,
    sendOnBoardForm,
    sendContactCard,
    updateChatAfterAddingMessage,
    getChatMessageList
} from "../../redux/actions";
import { CustomModal } from "../../components/modal";
import ContactAlertModal from "../../components/Contact/ContactAlertModal";
import { errorToaster } from "../../utils/loggerUtils";
import { MIN_AGE_STATUS, SEGMENT_TYPE } from "../../constant";
// import { FormFieldSlug, MIN_AGE_STATUS, SEGMENT_TYPE } from "../../constant";
import CustomToolTip from "../../components/Inbox/inboxChatboxInput/Tooptip";
import { convertDateTime } from "../../utils/dateTimeUtils";
import ConfirmationModal from "./ConfirmationModal";
import uuidv4 from "uuid/v4";
import AgeStatusContainer from "../../components/shared/AgeStatusContainer";
import EditWrapper from "../../components/atoms/EditWrapper";
class NewContactSidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddSegment: false,
            contactId: 0,
            editEmail: false,
            editGender: false,
            editMemberNo: false,
            editPhoneNumber: false,
            editBirthday: false,
            more: true,
            showContactCardAlert: false,
            showContactCard: false,
            showOnBoardFormAlert: false,
            showOnBoardForm: false,
            disabled: false,
            allowedSlugs: [],
            fetching: false,
            showOnBoardFormLoading: false,
            sendContactCardLoading: false,
            checkboxStatus: false
        };
        this.abortController = new AbortController();
        this.phoneEdit = React.createRef();
        this.EmailEdit = React.createRef();
        this.GenderEdit = React.createRef();
        this.BirthdayEdit = React.createRef();
        this.memberNumberEdit = React.createRef();
    }

    documentClick = (e) => {
        if (this.props.emailError || this.props.birthdayError) {
            return;
        }
        if (this.phoneEdit.current && this.phoneEdit.current.contains(e.target)) {
            this.setState({
                editEmail: false,
                editGender: false,
                editMemberNo: false,
                editBirthday: false
            });
        } else if (this.EmailEdit.current && this.EmailEdit.current.contains(e.target)) {
            this.setState({
                editGender: false,
                editPhoneNumber: false,
                editMemberNo: false,
                editBirthday: false
            });
        } else if (this.GenderEdit.current && this.GenderEdit.current.contains(e.target)) {
            this.setState({
                editEmail: false,
                editPhoneNumber: false,
                editMemberNo: false,
                editBirthday: false
            });
        } else if (this.BirthdayEdit.current && this.BirthdayEdit.current.contains(e.target)) {
            this.setState({
                editEmail: false,
                editPhoneNumber: false,
                editMemberNo: false,
                editGender: false
            });
        } else if (
            this.memberNumberEdit.current &&
            this.memberNumberEdit.current.contains(e.target)
        ) {
            this.setState({
                editEmail: false,
                editPhoneNumber: false,
                editGender: false,
                editBirthday: false
            });
        } else {
            this.setState({
                editEmail: false,
                editPhoneNumber: false,
                editGender: false,
                editBirthday: false,
                editMemberNo: false
            });
        }
        if (
            !this.editEmail &&
            !this.editPhoneNumber &&
            !this.editGender &&
            !this.editBirthday &&
            !this.editMemberNo &&
            this.props.isFieldUpdated
        ) {
            this.props.onSubmit(true);
        }
    };

    bindDocumentListener = () => {
        document.getElementById("root").addEventListener("click", this.documentClick, false);
    };

    unbindDocumentListener = () => {
        document.getElementById("root").removeEventListener("click", this.documentClick, false);
    };
    componentDidMount() {
        const { getRegisterFormFields, user } = this.props;
        getRegisterFormFields({ CompanyId: user.Company.Id }).then((val) => {
            let formFields = val.FormFields;
            this.setState({
                allowedSlugs: formFields.map((val) => {
                    return val.FormFieldId.Slug;
                })
            });
        });
        this.setState({
            contactId: this.props.currentContactId
        });
    }
    componentDidUpdate() {
        if (this.props.isEditing) {
            this.bindDocumentListener();
        }
        if (this.props.currentContactId !== this.state.currentContactId) {
            this.setState({
                currentContactId: this.props.currentContactId
            });
            if (this.props.currentContactId) {
                this.getChatNotification(this.props.currentContactId);
            }
        }
    }
    AddSegmentToggle = (flag) => {
        this.setState({
            showAddSegment: flag
        });
    };
    isSegmentSelected = (Id) => {
        for (const segment of this.props.selectedSegments) {
            if (segment.Id === Id) {
                return true;
            }
        }
        return false;
    };
    changeEditModePhoneNumber = (flag) => {
        this.setState({
            editPhoneNumber: flag
        });
    };
    changeEditModeEmail = (flag) => {
        this.setState({
            editEmail: flag
        });
    };
    getChatNotification = (id) => {
        const { getChatNotificationStatus } = this.props;
        getChatNotificationStatus(id);
    };
    changeEditModeGender = (flag) => {
        this.setState({
            editGender: flag
        });
    };
    changeEditBirthday = (flag) => {
        this.setState({
            editBirthday: flag
        });
    };
    changeEditModeMemberNo = (flag) => {
        this.setState({
            editMemberNo: flag
        });
    };
    handleMoreLessSlider = () => {
        this.setState({
            ...this.state,
            more: !this.state.more
        });
    };
    addSegment = (segmentId) => {
        let updateSegment = [];
        let isSelected = this.isSegmentSelected(segmentId.Id);
        if (isSelected) {
            this.props.selectedSegments.forEach((segment) => {
                if (segment.Id !== segmentId.Id) {
                    updateSegment.push(segment);
                }
            });
        } else {
            updateSegment = this.props.selectedSegments;
            updateSegment.push(segmentId);
        }
        this.props.onSegmentChange(updateSegment);
    };
    handleContactCardStatus = () => {
        this.setState({ ...this.state, showContactCard: !this.state.showContactCard });
    };
    handleshowOnBoardFormStatus = () => {
        this.setState({ ...this.state, showOnBoardForm: !this.state.showOnBoardForm });
    };
    handleCheckboxStatus = (e) => {
        this.setState({ ...this.state, checkboxStatus: !this.state.checkboxStatus });
    };
    handleshowOnBoardFormAlertStatus = () => {
        this.setState({ ...this.state, showOnBoardFormAlert: !this.state.showOnBoardFormAlert });
    };
    handleContactCardAlertStatus = () => {
        this.setState({ ...this.state, showContactCardAlert: !this.state.showContactCardAlert });
    };
    handleshowOnBoardCard = () => {
        this.setState({ ...this.state, showOnBoardFormLoading: true });
        this.props
            .sendOnBoardForm({
                Ids: [this.props.currentContactId],
                SentOnboardForm: this.state.checkboxStatus ? 0 : 1
            })
            .then((res) => {
                const uniqueId = uuidv4();
                this.setState({
                    ...this.state,
                    showOnBoardForm: false,
                    showOnBoardFormAlert: true,
                    showOnBoardFormLoading: false
                });
                let time = convertDateTime(Date.now())
                    .getTimeZoneMoment()
                    .format("MM/DD/YYYY, hh:mm A")
                    .replace(", ", " at ");
                if (this.props.currentContactId) {
                    this.props.getChatNotificationStatus(this.props.currentContactId);
                }
                if (
                    this.props.currentChat &&
                    this.props.currentChat.data &&
                    this.props.currentChat.data.LastChatEvent &&
                    this.props.currentChat.data.LastChatEvent.ChatId
                ) {
                    this.props.getChatMessageList(
                        this.props.currentChat &&
                            this.props.currentChat.data &&
                            this.props.currentChat.data.LastChatEvent &&
                            this.props.currentChat.data.LastChatEvent.ChatId
                    );

                    this.props.updateChatAfterAddingMessage({
                        ChatId:
                            this.props.currentChat &&
                            this.props.currentChat.data &&
                            this.props.currentChat.data.LastChatEvent &&
                            this.props.currentChat.data.LastChatEvent.ChatId,
                        Content: `Onboard form sent on ${time}`,
                        SenderId: this.props.user.Id,
                        SenderType: ChatMessageTypes.ONBOARDFORMNOTIFICATION,
                        Id: uniqueId,
                        CreatedAt: Date.now(),
                        isAcknowledged: false,
                        Error: false,
                        SenderInfo: JSON.stringify({
                            IsCompany: false,
                            SenderName: this.props.user.Name,
                            SenderId: this.props.user.Id,
                            AutoResponse: false
                        })
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                errorToaster(e.Message);
                this.setState({ ...this.state, showOnBoardFormAlert: !this.showOnBoardFormAlert, showOnBoardFormLoading: false });
            })
            // .finally(() => {
            //     this.setState({ ...this.state, showOnBoardFormLoading: false });
            // });
    };
    handleSendContactCard = () => {
        this.setState({ ...this.state, sendContactCardLoading: true });
        this.props
            .sendContactCard({ Ids: [this.props.currentContactId] })
            .then(() => {
                const uniqueId = uuidv4();
                this.setState({
                    ...this.state,
                    showContactCard: !this.state.showContactCard,
                    showContactCardAlert: !this.state.showContactCardAlert
                });
                let time = convertDateTime(Date.now())
                    .getTimeZoneMoment()
                    .format("MM/DD/YYYY, hh:mm A")
                    .replace(", ", " at ");
                if (
                    this.props.currentChat &&
                    this.props.currentChat.data &&
                    this.props.currentChat.data.LastChatEvent &&
                    this.props.currentChat.data.LastChatEvent.ChatId
                ) {
                    this.props.getChatMessageList(
                        this.props.currentChat &&
                            this.props.currentChat.data &&
                            this.props.currentChat.data.LastChatEvent &&
                            this.props.currentChat.data.LastChatEvent.ChatId
                    );
                    this.props.getChatNotificationStatus(this.props.currentContactId);
                    this.props.updateChatAfterAddingMessage({
                        ChatId:
                            this.props.currentChat &&
                            this.props.currentChat.data &&
                            this.props.currentChat.data.LastChatEvent &&
                            this.props.currentChat.data.LastChatEvent.ChatId,
                        Content: `Contact card form sent on ${time}`,
                        SenderId: this.props.user.Id,
                        SenderType: ChatMessageTypes.CONTACTCARDNOTIFICATION,
                        Id: uniqueId,
                        CreatedAt: Date.now(),
                        isAcknowledged: false,
                        Error: false,
                        SenderInfo: JSON.stringify({
                            IsCompany: false,
                            SenderName: this.props.user.Name,
                            SenderId: this.props.user.Id,
                            AutoResponse: false
                        })
                    });
                } else {
                    this.props.getChatNotificationStatus(this.props.currentContactId);
                }
            })
            .catch((err) => {
                console.log(err);
                errorToaster(err.Message);
            })
            .finally(() => {
                this.setState({ ...this.state, sendContactCardLoading: false });
            });
    };
    onNoteValueChange = (e) => {
        if (this.props.onInputChange && e && e.target.value && e.target.value.length < 161) {
            this.props.onInputChange(e);
        } else if (this.props.onInputChange && e && !e.target.value) {
            this.props.onInputChange(e);
        }
    };

    isOnBoardFormOrContactCardSendAllowed = () => {
        const { contactStatus } = this.props;
        if (!contactStatus) {
            return true;
        } else if (
            contactStatus === ContactStatus.OPT_OUT ||
            contactStatus === ContactStatus.OPT_INVITED
        )
            return false;
        return true;
    };

    render() {
        const {
            editBirthday,
            editPhoneNumber,
            showContactCard,
            showContactCardAlert,
            showOnBoardForm,
            showOnBoardFormAlert
        } = this.state;
        const {
            firstName,
            lastName,
            phoneNumber,
            onInputChange,
            email,
            gender,
            closeModal,
            birthday,
            memberId,
            disablePhoneNumber,
            birthdayError,
            note,
            isEditing,
            showCross
        } = this.props;
        return (
            <div
                className={
                    !isEditing
                        ? `contacts-sidebar-content contacts-sidebar-close`
                        : `contacts-sidebar-content contacts-sidebar-open`
                }
            >
                {showCross && (
                    <div
                        className="sidebar-closebtn"
                        onClick={(e) => {
                            if (this.props.isEditing) {
                                closeModal();
                            }
                        }}
                    >
                        <img
                            src={CloseSideBarIcon}
                            className="sidebarclosebtn img-fluid"
                            alt="img"
                        />
                    </div>
                )}

                <div className="profile-info-section">
                    <div className="profile-sidebar-avatar-container ">
                        <Avatar firstName={firstName} lastName={lastName} WithName={true} />
                        {phoneNumber && (
                            <div className="sidebar-profile-action-container">
                                <CustomToolTip
                                    text={
                                        !this.props.onBoardStatus
                                            ? `Onboard form not sent`
                                            : `Sent : ${convertDateTime(
                                                this.props.onBoardStatus.CreatedAt
                                            )
                                                .toCompanyTimezone()
                                                .replace(", ", " at ")}`
                                    }
                                >
                                    <div
                                        className="siderbar-each-action-container"
                                        onClick={() => {
                                            if (this.isOnBoardFormOrContactCardSendAllowed()) {
                                                this.handleshowOnBoardFormStatus();
                                            }
                                        }}
                                    >
                                        {!this.props.onBoardStatus ? (
                                            <img src={OnBoardIcon} className="img-fluid" alt="img" />
                                        ) : (
                                            <img
                                                src={onBoardIconGreen}
                                                className="img-fluid"
                                                alt="img"
                                            />
                                        )}
                                        <p>Onboard Form</p>
                                    </div>
                                </CustomToolTip>
                                <CustomToolTip
                                    text={
                                        !this.props.contactCardStatus
                                            ? `Contact card not sent`
                                            : `Sent : ${convertDateTime(
                                                this.props.contactCardStatus.CreatedAt
                                            )
                                                .toCompanyTimezone()
                                                .replace(", ", " at ")}`
                                    }
                                >
                                    <div
                                        className="siderbar-each-action-container"
                                        onClick={() => {
                                            if (this.isOnBoardFormOrContactCardSendAllowed()) {
                                                this.handleContactCardStatus();
                                            }
                                        }}
                                    >
                                        {!this.props.contactCardStatus ? (
                                            <img
                                                src={SendContactCardIcon}
                                                className="img-fluid"
                                                alt="img"
                                            />
                                        ) : (
                                            <img
                                                src={contactCardIconGreen}
                                                className="img-fluid"
                                                alt="img"
                                            />
                                        )}
                                        <p>Contact Card</p>
                                    </div>
                                </CustomToolTip>
                            </div>
                        )}
                        {showOnBoardForm ? (
                            <CustomModal
                                isOpen={showOnBoardForm}
                                handleModalState={() => this.handleshowOnBoardFormStatus()}
                            >
                                <ConfirmationModal
                                    handleCheckboxStatus={this.handleCheckboxStatus}
                                    checkboxStatus={this.state.checkboxStatus}
                                    checkBoxMessage={
                                        "Send onboard form only with no onboard message"
                                    }
                                    checkBox={true}
                                    color={"green"}
                                    handleModalClose={() => this.handleshowOnBoardFormStatus()}
                                    handleModalClick={this.handleshowOnBoardCard}
                                    title={"Send Onboard Form"}
                                    content={`User(s) will recieve a message with the Onboardform. Responses will be reflected in their contact card`}
                                    buttonContent={"Send Now"}
                                    buttonDisabled={this.state.showOnBoardFormLoading}
                                />
                            </CustomModal>
                        ) : null}
                        {showOnBoardFormAlert ? (
                            <CustomModal
                                isOpen={showOnBoardFormAlert}
                                handleModalState={() => this.handleshowOnBoardFormAlertStatus()}
                            >
                                <ContactAlertModal
                                    color={"green"} // todo
                                    handleClose={() => this.handleshowOnBoardFormAlertStatus()}
                                    icon={SuccessIcon}
                                    content={`Onboard form sent`}
                                />
                            </CustomModal>
                        ) : null}
                        {showContactCard ? (
                            <CustomModal
                                isOpen={showContactCard}
                                handleModalState={() => this.handleContactCardStatus()}
                            >
                                <ConfirmationModal
                                    color={"green"}
                                    handleModalClose={() => this.handleContactCardStatus()}
                                    handleModalClick={this.handleSendContactCard}
                                    title={"Resend Contact Card"}
                                    content={`The subscriber will receive a message with your contact card. Are you sure you want to continue?`}
                                    buttonContent={"Resend Now"}
                                    buttonDisabled={this.state.sendContactCardLoading}
                                />
                            </CustomModal>
                        ) : null}
                        {showContactCardAlert ? (
                            <CustomModal
                                isOpen={showContactCardAlert}
                                handleModalState={() => this.handleContactCardAlertStatus()}
                            >
                                <ContactAlertModal
                                    color={"green"} // todo
                                    handleClose={() => this.handleContactCardAlertStatus()}
                                    icon={SuccessIcon}
                                    content={`Contact Card was sent`}
                                />
                            </CustomModal>
                        ) : null}
                    </div>
                    <div className="profile-phone-email">
                        <ul>
                            <li ref={this.phoneEdit}>
                                <span>
                                    <img src={PhoneIcon} className="img-fluid" alt="img" />
                                    Phone Number
                                </span>

                                {editPhoneNumber ? (
                                    <InputMask
                                        mask="+1 (999)-999-9999"
                                        placeholder="Phone Number"
                                        name="phoneNumber"
                                        keyname="PhoneNumber"
                                        onChange={onInputChange}
                                        value={phoneNumber}
                                        type="text"
                                        required
                                        disabled={disablePhoneNumber}
                                        autoComplete="off"
                                        className="error-red"
                                    />
                                ) : (
                                    <div>
                                        <label>
                                            {getFormattedNumber(phoneNumber)}
                                            {/* <img src={EditPencil} style={{ marginLeft: 10 }} onClick={() => this.changeEditModePhoneNumber(true)} /> */}
                                        </label>
                                    </div>
                                )}
                            </li>
                            {
                                <li ref={this.EmailEdit}>
                                    <span>
                                        <img src={EmailIcon} className="img-fluid" alt="img" />
                                        Email Address
                                    </span>
                                    <EditWrapper value={email ? email : "N/A"}>
                                        <input
                                            placeholder="Email Address"
                                            name="email"
                                            keyname="Email"
                                            onChange={onInputChange}
                                            value={email}
                                            required
                                            type="email"
                                            className={
                                                this.props.emailError ? `Error-border-red` : ""
                                            }
                                        />
                                    </EditWrapper>
                                </li>
                            }
                        </ul>
                        {/* {!this.state.more && this.state.allowedSlugs && this.state.allowedSlugs.length > 2 && !(this.state.allowedSlugs.includes(FormFieldSlug.email) && this.state.allowedSlugs.length === 3) &&

                            (<div className="button-Less" onClick={() => this.handleMoreLessSlider()}>More <img src={IconArrowDownGrayIcon} alt="img" /></div>)
                        } */}
                    </div>
                    {
                        <div
                            className={
                                !this.state.more
                                    ? `profile-gender-birth`
                                    : `profile-gender-birth profile-gender-birth-slide-more`
                            }
                            style={{ height: this.state.allowedSlugs.includes(1) }}
                        >
                            <ul>
                                {
                                    <li ref={this.GenderEdit}>
                                        <span>Gender</span>
                                        <EditWrapper value={
                                            gender && GenderTypes.MALE === Number(gender)
                                            ? "Male"
                                            : gender &&
                                                GenderTypes.FEMALE === Number(gender)
                                                ? "Female"
                                                : "N/A"
                                        }>
                                        <select
                                            className="gender-select"
                                            name="gender"
                                            keyname="Gender"
                                            onChange={onInputChange}
                                            value={gender || ""}
                                        >
                                            {!gender ? (
                                                <option value="">Select Gender</option>
                                            ) : null}

                                            <option value={GenderTypes.MALE}>Male</option>
                                            <option value={GenderTypes.FEMALE}>Female</option>
                                        </select>
                                        </EditWrapper>
                                    </li>
                                }

                                {
                                    <li ref={this.BirthdayEdit}>
                                        <span>Date of Birth</span>
                                        {editBirthday ? (
                                            <InputMask
                                                maskPlaceholder={ShortDate}
                                                mask="99/99/9999"
                                                className={`form-control left ${
                                                    birthdayError ? "Error-border-red" : ""
                                                }`}
                                                placeholder="Birthday"
                                                name="birthday"
                                                keyname="Birthday"
                                                onChange={onInputChange}
                                                value={birthday || ""}
                                                autoComplete="off"
                                            />
                                        ) : (
                                            <div style={{ display: "flex" }}>
                                                {birthday ? birthday : "N/A"}
                                                <div style={{ marginLeft: "2%" }}>
                                                    {Is_Of_Age_Date(birthday, MIN_AGE_STATUS) ? (
                                                        <AgeStatusContainer />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </li>
                                }
                                <li ref={this.memberNumberEdit}>
                                    <span>Member #</span>
                                    <EditWrapper value={memberId || ""}>
                                        <input
                                            className="form-control"
                                            placeholder="#Membership  ( if you have one )"
                                            name="memberId"
                                            keyname="MemberId"
                                            onChange={onInputChange}
                                            value={memberId || ""}
                                        />
                                    </EditWrapper>
                                </li>
                            </ul>

                            {/* <div className="button-More" onClick={() => this.handleMoreLessSlider()}>Less <img src={IconArrowDownGrayIcon} alt="img" /></div> */}
                        </div>
                    }
                </div>

                <div className="profile-segment-section">
                    <div className="segment-content">
                        <img
                            src={SegmentPlusIcon}
                            className="addsegment-btn img-fluid"
                            alt="img"
                            onClick={(e) => this.AddSegmentToggle(true)}
                        />

                        {this.state.showAddSegment ? (
                            <div className="segment-content-dropdown">
                                <img
                                    src={AddSegmentCloseIcon}
                                    onClick={(e) => this.AddSegmentToggle(false)}
                                    className="addsegmentclose-btn img-fluid"
                                    alt="img"
                                />
                                <h4>Add Segments</h4>
                                <ul>
                                    {this.props.segments.map((segment, index) => {
                                        if (segment.SegmentType === SEGMENT_TYPE.NoSegment) {
                                            return <></>;
                                        }
                                        return (
                                            <li key={`${segment.Id}${index}`}>
                                                <div className="segment-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id={`active-item${segment.Id}`}
                                                        className="active-items"
                                                        checked={this.isSegmentSelected(segment.Id)}
                                                        onClick={this.addSegment.bind(
                                                            this,
                                                            segment
                                                        )}
                                                    />
                                                    <label
                                                        htmlFor={`active-item${segment.Id}`}
                                                    ></label>
                                                </div>
                                                <span>{segment.Title}</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        ) : (
                            ``
                        )}
                        <h4>Segments</h4>
                    </div>

                    <div className="segment-listing">
                        <ul>
                            {this.props.selectedSegments.map((segment, index) => {
                                if (segment.SegmentType === SEGMENT_TYPE.NoSegment) {
                                    return <></>;
                                }
                                return (
                                    <li key={index}>
                                        <span>
                                            <img
                                                src={SegmentListCross}
                                                className="img-fluid"
                                                alt="img"
                                                onClick={(e) => this.addSegment(segment)}
                                            />
                                            {segment.Title}
                                        </span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    <div className="segment-notes-content">
                        <h4>Notes</h4>
                    </div>

                    <div className="segment-notes-textarea">
                        <textarea
                            name="note"
                            onChange={this.onNoteValueChange}
                            value={note}
                            rows="4"
                        ></textarea>
                    </div>
                </div>
                {/* RESEND CARD  */}
                {/* <div className="sidebar-footer" onClick={this.handleSendContactCard}>
                    <p style={{ cursor: "pointer" }}>Resend Contact card</p>
                </div> */}

                {/* <Button
                        classNamees="delete-button"
                        text="Delete"
                        color="white"
                        onClick={onDeleteClick}
                        type="button"
                        loading={deleteLoading}
                        disabled={deleteLoading}
                        loaderProps={{ color: "green" }}
                    />
                    <Button
                        text="Save"
                        color="green"
                        type="submit"
                        loading={loading}
                        disabled={loading}
                    /> */}
                {showContactCardAlert ? (
                    <CustomModal
                        isOpen={showContactCardAlert}
                        handleModalState={() =>
                            this.setState({
                                ...this.state,
                                showContactCardAlert: !showContactCardAlert
                            })
                        }
                    >
                        <ContactAlertModal
                            color={"green"} // todo
                            handleClose={() =>
                                this.setState({
                                    ...this.state,
                                    showContactCardAlert: !showContactCardAlert
                                })
                            }
                            icon={SuccessIcon}
                            content={`Contact Card was sent`}
                        />
                    </CustomModal>
                ) : null}
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getRegisterFormFields: (data) => dispatch(getRegisterFormFields(data)),
        getChatNotificationStatus: (contactId) => dispatch(getChatNotificationStatus(contactId)),
        updateChatListOnStatusChange: (chatId) => dispatch(updateChatListOnStatusChange(chatId)),
        changeContactStatus: (data) => dispatch(changeContactStatus(data)),
        sendOnBoardForm: (data) => dispatch(sendOnBoardForm(data)),
        sendContactCard: (data) => dispatch(sendContactCard(data)),
        updateChatAfterAddingMessage: (data) => dispatch(updateChatAfterAddingMessage(data)),
        getChatMessageList: (id) => dispatch(getChatMessageList(id))
    };
};
const mapStateToProps = (state) => {
    return {
        currentChat: state.chat.currentChat,
        user: state.auth.user,
        contactCardStatus: state.chat.notificationStatus.contactCardStatus,
        onBoardStatus: state.chat.notificationStatus.onBoardStatus
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewContactSidebar);
