import React from "react";
import "../../../App.css";
import { Avatar } from "../../Avatar";
import { Hover } from "../../Hover/Hover";
import {
    changeContactStatus,
    updateChatListOnStatusChange,
    removeCurrentChat
} from "../../../redux/actions";
import { ContactStatus } from "../../../constant";
import { errorToaster } from "../../../utils/loggerUtils";
import withUser from "../../../hoc/withUser";
import { connect } from "react-redux";
import { ChatFilters } from "../../../config/constants";

function InboxMessageListItem(props) {
    let { active, name, date, onClick, unread = false, lastMessage, filterType } = props;
    let chatFirstName = name || "";
    let chatLastName = name || "";
    let chatSplitlLastName = name.split(" ")[1];
    if (chatSplitlLastName) {
        chatLastName = chatSplitlLastName;
    } else {
        chatLastName = chatFirstName[1] || "";
    }
    function limtToTwoLines(message) {
        if (message && message.length < 100) {
            return message;
        } else {
            return message ? message.slice(0, 97) + "..." : "...";
        }
    }
    function getChatStatus(status, filterType) {
        if (filterType === ChatFilters.LEAD) {
            return "Cold Lead";
        }
        if (filterType === ChatFilters.COLD_LEAD) {
            return "Lead";
        }
        if (status) {
            return "Open";
        } else {
            return "Close";
        }
    }
    function handleChangeChatType(e, status) {
        e.preventDefault();
        e.stopPropagation();
        let OptStatus = status ? ContactStatus.OptIn : ContactStatus.Closed;
        let body = {};
        if (filterType === ChatFilters.LEAD) {
            body = {
                Ids: [props.contactId],
                LeadStatus: 1,
                ColdStatus: 1
            };
        } else if (filterType === ChatFilters.COLD_LEAD) {
            body = {
                Ids: [props.contactId],
                LeadStatus: 1,
                ColdStatus: 0
            };
        } else {
            body = {
                Ids: [props.contactId],
                OptStatus: OptStatus
            };
        }
        props
            .changeContactStatus(body)
            .then((res) => {
                props.updateChatListOnStatusChange(props.contactId);

                props.removeCurrentChat();
            })
            .catch((err) => {
                errorToaster(err.Message);
            });
    }
    return (
        <>
            <div className={` tab-middle-boxes   ${active ? "active" : ""}`} onClick={onClick}>
                <div className="row">
                    <div className="w-1">
                        <Avatar
                            FirstName={chatFirstName}
                            LastName={chatLastName}
                            online={unread}
                            myClass="tab-box-img"
                        />
                    </div>
                    <div className="w-9">
                        <div
                            className={`tab-box-name ${
                                props.showIndicator ? `server-notification-indigator` : ``
                            }`}
                        >
                            <h4>{name}</h4>
                            <p>{limtToTwoLines(lastMessage)}</p>
                        </div>
                    </div>
                    <div className="w-2">
                        <Hover
                            onHover={
                                <div
                                    className="tab-box-time"
                                    onClick={(e) => {
                                        handleChangeChatType(e, props.isChatOptedIn);
                                    }}
                                >
                                    {" "}
                                    <p>{getChatStatus(props.isChatOptedIn, filterType)}</p>{" "}
                                </div>
                            }
                        >
                            <div className="tab-box-time">
                                <p>{date}</p>
                            </div>
                        </Hover>
                    </div>
                </div>
            </div>
        </>
    );
}
const mapDispatchToProps = (dispatch) => {
    return {
        removeCurrentChat: () => dispatch(removeCurrentChat()),
        updateChatListOnStatusChange: (chatId) => dispatch(updateChatListOnStatusChange(chatId)),
        changeContactStatus: (data) => dispatch(changeContactStatus(data))
    };
};
export default connect(null, mapDispatchToProps)(withUser(InboxMessageListItem));

