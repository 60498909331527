import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import uuidv4 from "uuid/v4";
import "../../../App.css";
import { InboxChatBoxHeader, InboxMessageArea, InboxMessageUnselected } from "../";
import {
    getChatMessageList,
    addMessage,
    changeMessageAcknowledgement,
    updateChatAfterAddingMessage,
    readChatMessage,
    imageUpload,
    setImageLoadingStatus,
    setImageLoadingScroll,
    updateChatListObject,
    getSegments,
    updateContact,
    updateChatReadStatus,
    deleteMessage
} from "../../../redux/actions";
import { ShortDate } from "../../../utils/validateHelperUtils";
import { ChatReadStatus, ContactStatus } from "../../../config/constants";
import { validateEmail } from "../../../utils/validateHelperUtils";
import Socket from "../../../utils/socketUtils";
import { getFormattedNumber } from "../../../utils/commonUtils";
import { scrollToBottom } from "../../../utils/scrollUtils";
import withUser from "../../../hoc/withUser";
import NewContactSidebar from "../../../views/AllContacts/NewContactSidebar";
class InboxChatbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            isEditing: false,
            firstName: "",
            lastName: "",
            phoneNumber: "",
            birthday: "",
            note: "",
            memberId: "",
            email: "",
            gender: "",
            segmentIds: [],
            currentContactSegments: [],
            emailError: false,
            birthdayError: false,
            firstNameError: false,
            lastNameError: false,
            phoneNumberError: false,
            isFieldUpdated: false,
            isLoading: false
        };
        this.initialFetch = false;
        this.abortController = new AbortController();
    }
    getUserData = () => {
        const { currentChatData } = this.props;
        let name = currentChatData.Contact
            ? `${currentChatData.Contact.FirstName} ${currentChatData.Contact.LastName}`
            : "-";

        let contact = currentChatData.Contact ? currentChatData.Contact.PhoneNumber : "-";
        return {
            name,
            contact
        };
    };
    isFieldsValid = () => {
        const { firstName, lastName, phoneNumber } = this.state;
        if (
            !firstName.trim().length ||
            !lastName.trim().length ||
            !phoneNumber.toString().trim().length
        ) {
            this.setState({
                firstNameError: !firstName.trim().length,
                lastNameError: !lastName.trim().length,
                phoneNumberError: !phoneNumber.toString().trim().length
            });
            return false;
        }
        return true;
    };
    getChatMessageList = () => {
        let id = this.props.match.params.id;
        this.abortController = new AbortController();
        this.props.getChatMessageList(id, {}, this.abortController.signal).then((res) => {
            scrollToBottom();
            this.initialFetch = true;
        });
    };
    componentDidMount() {
        this.getChatMessageList();
        this.props.getSegments();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.abortController.abort();
            this.setState({ Page: 1 });
            this.initialFetch = false;
            this.abortController = null;
            this.getChatMessageList();
        }
        if (
            prevProps.currentChatData !== this.props.currentChatData ||
            prevProps.currentChatMessages !== this.props.currentChatMessages
        ) {
            if (
                this.props.currentChatData &&
                this.props.currentChatData.ReadStatus === ChatReadStatus.UNREAD &&
                this.state.page === 1 &&
                this.props.currentChatMessages.length &&
                this.props.currentChatMessages[0].ChatId === this.props.currentChatData.Id
            ) {
                let chatId = this.props.currentChatData.Id;
                let messageId = this.props.currentChatMessages[
                    this.props.currentChatMessages.length - 1
                ].Id;
                this.props.updateChatReadStatus(chatId);
                Socket.messageRead({ chatId: chatId, chatMessageId: messageId });
            }
            this.handleEditClick();
        }
    }
    handleInputChange = (e) => {
        let isErrorFieldInState = false;
        if (`${e.target.name}Error` in this.state) {
            isErrorFieldInState = true;
        }
        this.setState(
            {
                [e.target.name]: e.target.value,
                ...(isErrorFieldInState && { [`${e.target.name}Error`]: false }),
                isFieldUpdated: true
            },
            (state) => {
                const isValid = this.isFieldsValid();
                if (isValid) {
                    if (this.state.birthday && !moment(this.state.birthday, ShortDate).isValid()) {
                        this.setState({ birthdayError: true });
                        return;
                    }
                    if (this.state.email && !validateEmail(this.state.email)) {
                        this.setState({ emailError: true });
                        return;
                    }
                }
            }
        );
    };
    handleSegmentChange = async (segments) => {
        if (this.state.isEditing) {
            let GetSegmentsId = await this.returnSegmentIds(segments);
            this.setState({ currentContactSegments: segments, segmentIds: GetSegmentsId }, () => {
                this.handleEditContactSubmit();
            });
        } else {
            this.setState({ segmentIds: segments });
        }
    };
    returnSegmentIds = (segments) => {
        const segmentIds = [];
        for (const segment of segments) {
            segmentIds.push(segment.Id);
        }
        return segmentIds;
    };
    handleEditContactSubmit = (e) => {
        const { segments } = this.props;
        const {
            currentContactId,
            firstName,
            lastName,
            email,
            gender,
            birthday,
            memberId,
            segmentIds,
            note
        } = this.state;
        const isValid = this.isFieldsValid();
        if (isValid) {
            if (birthday && !moment(birthday, ShortDate).isValid()) {
                this.setState({ birthdayError: true });
                return;
            }
            if (email && !validateEmail(email)) {
                this.setState({ emailError: true });
                return;
            }
            let formattedBirthday = birthday ? birthday.replace(/[/]/g, "-") : "";
            const bodyData = {
                Id: currentContactId,
                FirstName: firstName,
                LastName: lastName,
                ...(email && { Email: email }),
                ...(gender && { Gender: Number(gender) }),
                ...(memberId && { MemberId: memberId }),
                ...(birthday && { BirthdayDate: formattedBirthday }),
                ...(note && { Note: note }),
                SegmentIds: segmentIds
            };
            let Segments = segments.filter((val) => segmentIds.includes(val.Id));
            this.setState({ isLoading: true, isFieldUpdated: false });
            this.props
                .updateContact(bodyData)
                .then((res) => {
                    let Meta = null;
                    if (birthday || gender || note) {
                        Meta = JSON.stringify({
                            ...(formattedBirthday && { BirthdayDate: formattedBirthday }),
                            ...(gender && { Gender: gender }),
                            ...(note && { Note: note })
                        });
                    }
                    bodyData.Meta = Meta;
                    bodyData.Segments = Segments;
                    this.props.updateChatListObject(bodyData);
                })
                .finally(() => {
                    this.setState({ isLoading: false });
                });
        }
    };

    checkConnection(retryCount, messageData) {
        // Check if the retry count has reached 5
        if (retryCount >= 5) {
            console.log("5 tries done", messageData, this.props.currentChatMessages);
            this.props.changeMessageAcknowledgement(messageData.Id, false, true);
            setTimeout(() => {
                this.props.deleteMessage(messageData.Id);
            }, 2000);
            return; // Stop retrying
        }

        // Check if the navigator is online
        console.log(navigator.onLine, this.props.isSocketConnected);
        if (navigator.onLine) {
            if (this.props.isSocketConnected) {
                // Connection is online and socket is connected
                console.log("Connection is online and socket is connected");
                console.log('----RESENDING---')
                this.handleMessageResend(messageData, messageData.MediaUrl)
                return;
            }
        }

        // Retry the check after a delay of 2 seconds (2000 milliseconds)
        setTimeout(() => {
            this.checkConnection(retryCount + 1, messageData); // Retry with an incremented retry count
        }, 2000);
    }

    handleMessageSend = async (content, mediaObj) => {
        const { currentChatData, user, isSocketConnected } = this.props;
        const uniqueId = uuidv4();
        let MediaUrl = null;
        const upload = await this.uploadMediaObj(mediaObj);
        if (upload) {
            MediaUrl = upload;
        }
        let data = {
            ChatId: currentChatData.Id,
            Content: content,
            SenderId: user.Id,
            SenderType: 1,
            MediaUrl: MediaUrl
        };

        let socketData = {
            ...data,
            MessageUUID: uniqueId
        };
        let messageData = {
            ...data,
            Id: uniqueId,
            CreatedAt: Date.now(),
            isAcknowledged: false,
            Error: false,
            SenderInfo: JSON.stringify({
                IsCompany: false,
                SenderName: user.Name,
                SenderId: user.Id,
                AutoResponse: false
            })
        };
        this.props.setImageLoadingStatus(false);
        if (!navigator.onLine || !isSocketConnected) {
            // messageData.Error = true;
            this.checkConnection(0, messageData);
        } else {
            Socket.sendMessage(socketData, (data) => {
                this.props.changeMessageAcknowledgement(data.MessageUUID, true, false);
                this.props.updateChatAfterAddingMessage(messageData);
            });
        }
        this.props.addMessage(messageData);
    };
    uploadMediaObj = async (Obj) => {
        return new Promise((resolve, reject) => {
            if (Obj === null) {
                resolve(false);
            } else if (Obj.type === "image") {
                let formData = new FormData();
                formData.append("file", Obj.Obj);
                this.props.imageUpload(formData).then(
                    (res) => {
                        resolve(res.location);
                    },
                    (err) => {
                        this.props.setImageLoadingStatus(false);
                        reject(err);
                    }
                );
            } else {
                resolve(Obj.Obj.url);
            }
        });
    };
    handleMessageResend = (msgData, MediaUrl) => {
        const { Id: MessageUUID, ChatId, Content, SenderId, SenderType } = msgData;
        this.props.changeMessageAcknowledgement(MessageUUID, false, false);
        if (navigator.onLine && this.props.isSocketConnected) {
            let socketData = {
                ChatId,
                Content,
                SenderId,
                SenderType,
                MessageUUID,
                MediaUrl: MediaUrl
            };
            let messageData = {
                ...socketData,
                Id: MessageUUID,
                CreatedAt: Date.now()
            };
            Socket.sendMessage(socketData, (data) => {
                this.props.changeMessageAcknowledgement(MessageUUID, true, false);
                this.props.updateChatAfterAddingMessage(messageData);
            });
        } else {
            //TODO: Handle offline it is not Tested Yet it is not replciation of the real scenario
            Socket.init();
            setTimeout(() => {
                let socketData = {
                    ChatId,
                    Content,
                    SenderId,
                    SenderType,
                    MessageUUID,
                    MediaUrl: MediaUrl
                };
                let messageData = {
                    ...socketData,
                    Id: MessageUUID,
                    CreatedAt: Date.now()
                };
                Socket.sendMessage(socketData, (data) => {
                    this.props.changeMessageAcknowledgement(MessageUUID, true, false);
                    this.props.updateChatAfterAddingMessage(messageData);
                });
            }, 2000);
        }
    };

    handleScroll = (e) => {
        const { fetching, dataAvailable } = this.props;
        let id = this.props.match.params.id;
        let top = e.target.scrollTop < 170;
        let currentPage = this.state.page + 1;
        if (top && !fetching && dataAvailable && this.initialFetch) {
            this.props.setImageLoadingScroll(false);
            this.props
                .getChatMessageList(id, { Page: currentPage }, this.abortController.signal)
                .then((res) => {})
                .catch((err) => {});
            this.setState({ ...this.state, page: currentPage });
        }
    };
    handleEditClick = (e, contact) => {
        if (!this.props.currentChatData || !this.props.currentChatData.Contact) {
            return;
        }
        this.handleEditModalClose();
        const {
            Id,
            FirstName,
            LastName,
            PhoneNumber,
            Segments,
            Email,
            MemberId,
            Meta
        } = this.props.currentChatData.Contact;
        let birthday, gender, note;
        if (Meta) {
            try {
                let meta = JSON.parse(Meta);
                if (meta.BirthdayDate) {
                    birthday = meta.BirthdayDate.replace(/[-]/g, "/");
                }
                if (meta.Gender) {
                    gender = meta.Gender;
                }
                if (meta.Note) {
                    note = meta.Note;
                } else {
                    note = "";
                }
            } catch (err) {}
        }
        this.setState({
            showEditModal: true,
            currentContactId: Id,
            firstName: FirstName,
            lastName: LastName,
            ...(birthday && { birthday }),
            ...(gender && { gender }),
            ...(note && { note }),
            phoneNumber: PhoneNumber,
            note: note,
            email: Email,
            memberId: MemberId,
            currentContactSegments: [...Segments]
        });
    };
    handleEditModalClose = () => {
        this.setState({
            showEditModal: false,
            currentContactId: null,
            firstName: "",
            lastName: "",
            phoneNumber: "",
            note: "",
            email: "",
            birthday: "",
            gender: "",
            memberId: "",
            segmentIds: [],
            currentContactSegments: [],
            firstNameError: false,
            lastNameError: false,
            emailError: false,
            phoneNumberError: false,
            birthdayError: false
        });
    };
    handleIsEditing = () => {
        this.setState({ ...this.state, isEditing: !this.state.isEditing });
        this.handleEditClick();
    };
    isChatOptedIn = () => {
        const { currentChatData } = this.props;
        if (currentChatData.Contact) {
            return currentChatData.Contact.OptStatus === ContactStatus.OPT_IN;
        } else {
            if (this.props.isOptedOut) {
                return false;
            } else {
                return true;
            }
        }
    };
    render() {
        const {
            currentChatMessages,
            fetching,
            currentChatData,
            segments,
            segmentsLoading
        } = this.props;
        const {
            firstName,
            lastName,
            phoneNumber,
            email,
            birthday,
            gender,
            memberId,
            emailError,
            birthdayError,
            firstNameError,
            lastNameError,
            phoneNumberError,
            note,
            isLoading,
            currentContactSegments
        } = this.state;
        return (
            <>
                {currentChatData && currentChatData.Contact ? (
                    <div
                        className={
                            !this.state.isEditing
                                ? `chats-article-content`
                                : `chats-article-content chats-article-reduces`
                        }
                    >
                        <InboxChatBoxHeader
                            isEditing={this.state.isEditing}
                            changeEditingState={this.handleIsEditing}
                            name={this.getUserData().name}
                            contact={getFormattedNumber(this.getUserData().contact)}
                            contactData={currentChatData.Contact}
                            currentContactId={
                                currentChatData.Contact ? currentChatData.Contact.Id : null
                            }
                            isChatOptedIn={!this.isChatOptedIn()}
                        />
                        <InboxMessageArea
                            isEditing={this.state.isEditing}
                            handleScroll={this.handleScroll}
                            currentChatMessages={currentChatMessages}
                            fetching={fetching}
                            handleMessageResend={this.handleMessageResend}
                            isChatOptedIn={!this.isChatOptedIn()}
                            handleMessageSend={this.handleMessageSend}
                        />
                        <NewContactSidebar
                            showCross={false}
                            isEditing={this.state.isEditing}
                            closeModal={this.handleIsEditing}
                            onInputChange={this.handleInputChange}
                            onSubmit={this.handleEditContactSubmit}
                            firstName={firstName}
                            lastName={lastName}
                            phoneNumber={phoneNumber}
                            note={note}
                            birthday={birthday}
                            email={email}
                            gender={gender}
                            memberId={memberId}
                            disablePhoneNumber={true}
                            firstNameError={firstNameError}
                            lastNameError={lastNameError}
                            emailError={emailError}
                            birthdayError={birthdayError}
                            phoneNumberError={phoneNumberError}
                            onSegmentChange={this.handleSegmentChange}
                            loading={isLoading}
                            deleteLoading={false}
                            wrapperClass={"editContactsModalWrapper"}
                            title={"Edit Contact"}
                            showDeleteButton={true}
                            segments={segments}
                            selectedSegments={currentContactSegments}
                            segmentsLoading={segmentsLoading}
                            currentContactId={currentChatData.Contact.Id}
                            isFieldUpdated={this.state.isFieldUpdated}
                        />
                    </div>
                ) : (
                    <InboxMessageUnselected />
                )}
            </>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateChatListObject: (chatObject) => dispatch(updateChatListObject(chatObject)),
        imageUpload: (bodyData) => dispatch(imageUpload(bodyData)),
        getSegments: () => dispatch(getSegments()),
        setImageLoadingStatus: (status) => dispatch(setImageLoadingStatus(status)),
        changeMessageAcknowledgement: (messageId, acknowledge, error) =>
            dispatch(changeMessageAcknowledgement(messageId, acknowledge, error)),
        deleteMessage: (messageId) => dispatch(deleteMessage(messageId)),
        updateChatAfterAddingMessage: (data) => dispatch(updateChatAfterAddingMessage(data)),
        updateChatReadStatus: (data) => dispatch(updateChatReadStatus(data)),
        addMessage: (data) => dispatch(addMessage(data)),
        getChatMessageList: (id, paramsObj, signal) =>
            dispatch(getChatMessageList(id, paramsObj, signal)),
        readChatMessage: (chatId, messageId) => dispatch(readChatMessage(chatId, messageId)),
        setImageLoadingScroll: (data) => dispatch(setImageLoadingScroll(data)),
        updateContact: (bodyData) => dispatch(updateContact(bodyData))
    };
};
const mapStateToProps = (state) => {
    return {
        segments: state.contacts.segments.data,
        segmentsLoading: state.contacts.segments.fetching,
        currentChatData: state.chat.currentChat.data,
        currentChatMessages: state.chat.currentChat.messages,
        fetching: state.chat.currentChat.fetching,
        dataAvailable: state.chat.currentChat.dataAvailable,
        isSocketConnected: state.socketConnected
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withUser(InboxChatbox));

